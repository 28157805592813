import React from 'react';
import ReactTooltip from 'react-tooltip'

import BoyScouts from '~images/brands/boy-scouts@2x.png';
import CalRipkin from '~images/brands/cal-ripkin@2x.png';
import ClemsonU from '~images/brands/clemson-u@2x.png';
import FloridaStateU from '~images/brands/fsu@2x.png';
import Goddard from '~images/brands/goddard@2x.png';
import MusicCityBmx from '~images/brands/music-city-bmx@2x.png';
import StateOfTheU from '~images/brands/state-of-the-u@2x.png';
import TownOfJupiter from '~images/brands/town-of-jupiter@2x.png';
import UnitedWay from '~images/brands/united-way@2x.png';
import UsYouthSoccer from '~images/brands/us-youth-soccer@2x.png';
import Usta from '~images/brands/usta@2x.png';
import WilliamsGrove from '~images/brands/williams-grove@2x.png';
import Ymca from '~images/brands/ymca@2x.png';

import useStyles from './brands.styles';

const DEFAULT_SIZE = 50;

const BRANDS = [{
  name: 'Boy Scouts Of America',
  src: BoyScouts,
  size: 70
}, {
  name: 'Cal Ripkin Baseball',
  src: CalRipkin
}, {
  name: 'Clemson University',
  src: ClemsonU,
  size: 45
}, {
  name: 'Florida State University',
  src: FloridaStateU
}, {
  name: 'The Goddard School for Early Childhood Development',
  src: Goddard
}, {
  name: 'Music City BMX',
  src: MusicCityBmx
}, {
  name: 'SB Nation — State of the U',
  src: StateOfTheU,
  size: 70
}, {
  name: 'Town of Jupiter',
  src: TownOfJupiter,
  size: 80
}, {
  name: 'United Way',
  src: UnitedWay
}, {
  name: 'U.S. Youth Soccer',
  src: UsYouthSoccer
}, {
  name: 'U.S. Tennis Association',
  src: Usta
}, {
  name: 'Williams Grove',
  src: WilliamsGrove
}, {
  name: 'Y.M.C.A.',
  src: Ymca
}]

const Brands = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.brands}>
      <div className={classes.title}>You're In Good Company.</div>
      <div className={classes.list}>
        {BRANDS.map((item, idx) => (
          <img
            key={idx}
            src={item.src}
            alt={item.name}
            className={classes.image}
            width="auto"
            height={item.size || DEFAULT_SIZE}
            data-tip={item.name}
          />
        ))}
      </div>
      <ReactTooltip className={classes.tooltip} effect="solid" />
    </div>
  )
}

export default Brands
