import { createUseStyles } from 'react-jss'
import color from 'color';

import {
  bar,
  link
} from '~styles/mixins.styles'

import {
  BREAKPOINTS,
  COLORS,
  ELEMENTS,
  LAYOUT
} from '~styles/vars.styles'

import lineartTexture from '~images/lineart-texture-dark.svg';

export default createUseStyles({
  hero: {
    backgroundImage: `url(${lineartTexture}), radial-gradient(circle at center left, ${COLORS.ACCENT}, ${color(COLORS.ACCENT).lighten(0.15).string()})`,
    backgroundRepeat: 'repeat, no-repeat',
    backgroundSize: '280px, 100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: [ELEMENTS.HEADER.HEIGHT, 30, 0, 30],
    width: '100%',

    '& $text': {
      margin: [0, 'auto'],
      maxWidth: 500
    },

    [`@media (min-width: ${BREAKPOINTS.HERO_PHONE_SIDE}px)`]: {
      overflow: 'visible'
    },

    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      padding: [ELEMENTS.HEADER.HEIGHT, 0, 0, 0]
    }
  },

  sectionFixed: {
    maxWidth: LAYOUT.MAX_WIDTH,
    margin: [0, 'auto'],
    padding: [40, LAYOUT.HORIZONTAL_PADDING, 0, LAYOUT.HORIZONTAL_PADDING],
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [`@media (min-width: ${BREAKPOINTS.WHY_RAINEDOUT_BIG}px)`]: {
      flexDirection: 'row',
      padding: 0
    }
  },
  
  sectionFixedReverse: {
    extend: 'sectionFixed',
    flexDirection: 'column',
    [`@media (min-width: ${BREAKPOINTS.WHY_RAINEDOUT_BIG}px)`]: {
      flexDirection: 'row-reverse',
    }
  },

  title: {
    color: COLORS.PURPLE_01,
    fontSize: 45,
    fontWeight: 600,
    letterSpacing: '-0.045em',
    lineHeight: '1.25em',
    marginBottom: 20
  },

  subtitle: {
    color: COLORS.BLUE_MUTED_01,
    fontSize: 28,
    letterSpacing: '-0.045em',
    lineHeight: '1.25em',
    fontWeight: 600,
    marginBottom: 30
  },

  side: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },

  right: {
    extend: 'side',
    [`@media (min-width: ${BREAKPOINTS.WHY_RAINEDOUT_BIG}px)`]: {
      flexBasis: BREAKPOINTS.WHY_RAINEDOUT_BIG / 2
    }
  },

  left: {
    paddingBottom: '56.25%',
    marginBottom: 30,
    [`@media (min-width: ${BREAKPOINTS.WHY_RAINEDOUT_BIG}px)`]: {
      paddingBottom: 0,
      marginBottom: 0,

      extend: 'side',
      flexBasis: BREAKPOINTS.WHY_RAINEDOUT_BIG / 2
    }
  },

  image: {
    backgroundSize: 'cover',
    width: '100%',
    height: 'auto',
    paddingBottom: '56.25%',
    [`@media (min-width: ${BREAKPOINTS.WHY_RAINEDOUT_BIG}px)`]: {
      paddingBottom: '100%'
    }
  },

  text: {
    padding: 20,
    [`@media (min-width: ${BREAKPOINTS.WHY_RAINEDOUT_BIG}px)`]: {
      padding: 60
    }
  },

  body: {
    color: COLORS.BLUE_MUTED_01,
    marginBottom: 20,
    fontSize: 18,
    fontWeight: 400,
    letterSpacing:'-0.03777em'
  },

  link: {
    ...link({})
  },

  bar: {
    ...bar,
    backgroundImage: `url(${lineartTexture}), radial-gradient(circle at center left, #F7F8FA, #E1E9FF)`,
    backgroundRepeat: 'repeat, no-repeat',
    backgroundSize: '280px, 100%',

    color: COLORS.BLUE_02,
    letterSpacing: '-0.05em',
    padding: [0, 30],
    textAlign: 'center',

    [`@media (min-width: ${BREAKPOINTS.BAR_BIG}px)`]: {
      fontSize: 24,
    }
  },

  barLink: {
    ...link({ color: COLORS.BLUE_02, fontWeight: 600, fontSize: 'inherit' }),
    '&:hover $actionIcon': {
      transform: 'translate3d(10px, 0, 0)'
    },
  },

  actionIcon: {
    display: 'inline-block',
    transform: 'translate3d(0, 0, 0)',
    transition: `transform ${200}ms ease-in-out`
  },

  barIcon: {
    display: 'inline-block',
    marginLeft: 10,
    position: 'relative',
    top: 4
  },

  testimonial: {
    flex: 1,
    margin: 15,
    maxWidth: 400,
    minWidth: 320
  },

  sectionLink: {
    padding: `calc(1.45rem + ${ELEMENTS.HEADER.HEIGHT}px) ${LAYOUT.HORIZONTAL_PADDING}px`
  }
})