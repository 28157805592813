import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby'

import Icon from '~components/icon/icon.component';

import useStyles from './feature.styles';

const Feature = props => {
  const classes = useStyles(props);

  return (
    <div className={classNames(
      [classes.feature],
      {
        [props.className]: !!props.className
      }
    )}>
      <div className={classes.icon}>{props.featureIcon}</div>
      <div className={classes.title}>{props.featureName}</div>
      <div className={classes.body}>{props.featureDescription}</div>
      {props.featureLink ? 
      <Link
          to={props.featureLink}
          className={classes.actionLink}
      >
        {props.featureAction}
        <div className={classes.actionIcon}>
          <Icon name="arrow-right" size={24} />
        </div>
      </Link> : null}
    </div>
  )
}

Feature.propTypes = {
  featureIcon: PropTypes.node,
  featureName: PropTypes.string.isRequired,
  featureAction: PropTypes.string.isRequired,
  featureDescription: PropTypes.string.isRequired,
  featureLink: PropTypes.string
}

Feature.defaultProps = {
  featureIcon: null,
  featureLink: null
}

export default Feature
