import {createUseStyles} from 'react-jss'

import {
  COLORS
} from '~styles/vars.styles'

import {
  link
} from '~styles/mixins.styles'

export default createUseStyles({
  feature: {
    padding: 20,
    '&:hover $actionIcon': {
      transform: 'translate3d(10px, 0, 0)'
    }
  },

  icon: {
    marginBottom: 10
  },

  title: {
    color: COLORS.PURPLE_01,
    fontSize: 28,
    fontWeight: 600,
    letterSpacing: '-0.045em',
    lineHeight: '1.25em',
    marginBottom: 20
  },

  body: {
    color: COLORS.BLUE_MUTED_01,
    marginBottom: 20,
    fontSize: 18,
    fontWeight: 400,
    letterSpacing:'-0.03777em'
  },

  actionLink: {
    ...link({ color: COLORS.BLUE_02, fontSize: 18 }),
    display: 'flex',
    alignItems: 'center'
  },

  actionIcon: {
    marginLeft: 10,
    transform: 'translate3d(0, 0, 0)',
    transition: `transform ${200}ms ease-in-out`
  }
})