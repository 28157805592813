import React from 'react';

import UnlimitedGroups from '~images/artwork/groups.svg';
import Airplane from '~images/artwork/airplane.svg';
import Sharing from '~images/artwork/sharing.svg';
import Rocket from '~images/artwork/rocket.svg';

import Feature from '~components/feature/feature.component';

import useStyles from './features.styles';

const Features = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.features}>
      
      <div className={classes.featureGroup}>
        <Feature
          className={classes.feature}
          featureIcon={
            <img src={Airplane} alt="Dialogue bubble" className={classes.icon} />
          }
          featureName="Send Messages 3 Easy Ways"
          featureAction="Get Started"
          featureDescription="Get your message out fast and send it directly from your phone using our Mobile Portal, Mobile SMS or from your Team Page.  Create an account and log in to see how!"
          featureLink="/create-organization"
        />
        <Feature
          className={classes.feature}
          featureIcon={
            <img src={UnlimitedGroups} alt="Dialogue bubble" className={classes.icon} />
          }
          featureName="Unlimited Groups"
          featureAction="Get Started"
          featureDescription="Create unlimited groups within your account for different teams, clubs or classrooms - anything!  Send a message to everyone or just that group! You also add administrators to each group that can also send messages when you can't.  Create an account and log in to see how!"
          featureLink="/create-organization"
        />
        <Feature
          className={classes.feature}
          featureIcon={
            <img src={Sharing} alt="Dialogue bubble" className={classes.icon} />
          }
          featureName="Sharing is Easy"
          featureAction="Get Started"
          featureDescription="Send your notification from RainedOut and we will automatically post it to Facebook, Twitter and/or your website at the same time!  Create an account and log in to see how!"
          featureLink="/create-organization"
        />
        <Feature
          className={classes.feature}
          featureIcon={
            <img src={Rocket} alt="Dialogue bubble" className={classes.icon} />
          }
          featureName="Joining is Easy"
          featureAction="Get Started"
          featureDescription="Create an account and invite people to join using a keyword, an email invite, or a link on your website. Create an account and log in to see how!"
          featureLink="/create-organization"
        />
      </div>
    </div>
  )
}

export default Features
