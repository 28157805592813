import {createUseStyles} from 'react-jss'

import {
  BREAKPOINTS
} from '~styles/vars.styles'

import dottedLines from '~images/background-dotted-lines.svg';

export default createUseStyles({
  features: {
    background: `url(${dottedLines})`,
    backgroundPosition: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 40,
    [`@media (min-width: ${BREAKPOINTS.FEATURES_GRID_FOUR}px)`]: {
      padding: 60
    }
  },

  featureGroup: {
   display: 'flex',
   flexDirection: 'row',
   flexWrap: 'wrap',
   justifyContent: 'center'
  },

  feature: {
    flex: 1,
    margin: 15,
    minWidth: 300,
    maxWidth: 400,
    [`@media (min-width: ${BREAKPOINTS.FEATURES_GRID_TWO}px)`]: {
      flexBasis: '50%'
    },
    [`@media (min-width: ${BREAKPOINTS.FEATURES_GRID_FOUR}px)`]: {
      flexBasis: '20%'
    }
  }
})