import {createUseStyles} from 'react-jss'

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

import {
  textTitle,
  tooltip
} from '~styles/mixins.styles'

export default createUseStyles({
  brands: {
    background: COLORS.LIGHT_02,
    padding: 60,
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      padding: 120
    }
  },

  title: {
    ...textTitle,
    color: COLORS.BLUE_MUTED_02
  },

  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },

  image: {
    margin: 20,
    filter: 'grayscale(0.95)',
    opacity: 0.3,
    '&:hover': {
      transition: `all ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`,
      filter: 'grayscale(0.1)',
      opacity: 1
    }
  },

  tooltip: {
    ...tooltip
  }
})