import React from "react"
import { Link } from 'gatsby'

import { LAYOUT_NAME_FULL } from '~constants';

import CustomerPhone from '~images/photos/customer-phone.jpg';
import CustomerRun from '~images/photos/customer-run.jpg';
import CustomerShopping from '~images/photos/customer-shopping.jpg';
import CustomerCoffee from '~images/photos/customer-coffee.jpg';

import Brands from '~components/brands/brands.component';
import Features from '~components/features/features.component';
import Icon from '~components/icon/icon.component';

import Layout from "~components/layout/layout.component"
import SEO from "~components/seo/seo.component"

import useStyles from '~components/why-rainedout/why-rainedout.styles';

const WhyRainedOutPage= props => {
  const classes = useStyles(props);
  return (
    <Layout layout={LAYOUT_NAME_FULL}>
      <SEO title="Why RainedOut?" />
      <section className={classes.hero}>
        <div className={classes.text}>
          <div className={classes.title}>Why RainedOut?</div>
          <div className={classes.body}>
        </div>
          <p>Practice rescheduled?  Race delayed?  Traffic backed up?  Meeting moved to another room?  No Problem!  Let everyone know in seconds with RainedOut and get back to your day!</p>
        </div>
      </section>

      <section className={classes.sectionFixedReverse}>
        <div className={classes.left}>
          <div className={classes.image} style={{ backgroundImage: `url(${CustomerPhone})` }} />
        </div>
        <div className={classes.right}>
          <div className={classes.text}>
            <div className={classes.subtitle}>Know Before You Go!</div>
            <div className={classes.body}>
              <p>RainedOut gets the word out fast – and you can send the message right from your phone. Account creation is free. Sending and receiving emails are free. Upgrading to text messages carries a small fee.</p>
              <p>RainedOut offers many features designed to save you time. For example, invite people to join you via keyword - this is one of our most popular features. Post your messages automatically to your website, FaceBook and Twitter. Use our Groups feature to only send messages to the people that need it.</p>
              <p>Lastly, RainedOut does not sell your data. Unlike many "free" services, we believe it is better to charge a small fee for our service.</p>
            </div>
          </div>
        </div>
      </section>

      <section className={classes.sectionFixed}>
        <div className={classes.left}>
          <div className={classes.image} style={{ backgroundImage: `url(${CustomerRun})` }} />
        </div>
        <div className={classes.right}>
         <div className={classes.text}>
            <div className={classes.subtitle}>No Ads. No App. No Stress.</div>
            <div className={classes.body}>
              <p>RainedOut Just Works…and on everyone’s phone – no need to download an App.  Most messages are delivered in less than a second.</p>
              <p>No Ads means that your messaging is just that….your messaging.</p>
            </div>
         </div>
        </div>
      </section>

      <div className={classes.bar}>
        <Link to="/create-organization" className={classes.barLink}>Get Started — Creating an account takes less than a minute!<span className={classes.actionIcon}><Icon className={classes.barIcon} name="arrow-right" size="1em" /></span></Link>
      </div>

      <section className={classes.sectionFixedReverse}>
        <div className={classes.left}>
          <div className={classes.image} style={{ backgroundImage: `url(${CustomerShopping})` }} />
        </div>
        <div className={classes.right}>
          <div className={classes.text}>
            <div className={classes.subtitle}>Sending your message with RainedOut is fast!</div>
            <div className={classes.body}>
              <p>Send a message to your team or organization directly from your phone in any one of 2 easy ways.  Our mobile portal gets you fast access to all of your groups.  You can also send directly from your SMS application.  Lastly, you can send directly from your Account Portal.   Whatever you choose, you can count on RainedOut to get your message out fast.</p>
            </div>
          </div>
        </div>
      </section>

      <section className={classes.sectionFixed}>
        <div className={classes.left}>
          <div className={classes.image} style={{ backgroundImage: `url(${CustomerCoffee})` }} />
        </div>
        <div className={classes.right}>
          <div className={classes.text}>
            <div className={classes.subtitle}>Privacy Pledge</div>
            <div className={classes.body}>
              <p>Rest easy with RainedOut’s Data Privacy Pledge – many companies that offer notifications will sell your data.  You see them in the news all the time.  At RainedOut, we think data privacy is important.  We will not sell your data to anyone.</p>
            </div>
          </div>
        </div>
      </section>

      <div className={classes.bar}>"RainedOut makes my life easier and they don't sell my data. I like that!" —Wulf K.</div>

      {/* <section className={classes.sectionFixed}>
        <div className={classes.title}>Pick A Plan!</div>
        <div>
          <div className={classes.subtitle}>Simple, easy pricing</div>
          <div className={classes.body}>
            <p>Two plans that put you in charge.  Pick the best one for you <Link to="/pick-a-plan" className={classes.link}>right here</Link>.</p>
          </div>
        </div>
        <div>
          <div className={classes.subtitle}>Proven Technology</div>
          <div className={classes.body}>
            <p>RainedOut has been delivering messages for over 10 years.  Our technology is dependable and fast – most notifications are delivered in less than a second.</p>
          </div>
        </div>
      </section> */}

      <Brands />

      <Features />
      
      <section className={classes.sectionLink}>
        <Link to="/">Go back to the homepage</Link>
      </section>
    </Layout>
  )
}

export default WhyRainedOutPage
